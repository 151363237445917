:root {
  --gray: #858fa2;
  --white: #ffffff;
  --theme-red: #ef1c51;
  --theme-red-sec: rgba(239, 28, 81, 0.25);

  --theme-red-disable: #f17090;
  --seachbar: #f3f3f4;
  --footer-back: #151515;
  --growth-green: #25e783;
  --warning: #da480e;
  --gray-secondary: #4a5568;
  --black: #000;
  --input-border: #d8d8d8;
  --salesCard-back: #151515;
  --placeholder: #9e9ea7;
  --text-back: #e2e2e4;
  --table-border: #d4d4d4;
  --text-secondary: #4a5568;
  --heading-text: #2d3748;
  --disabled-button: #cccccc;
  --disabled-button-text: #666666;
  --audio-back: #a1a1a1d0;
  --popup-back: #d9d9d9;
  --font: "Poppins";
  --bar-bg: #ffe3d4;
  --dark-bg: #3e3e3e;
  --twi-clr: #00acee;

  --landing-input: #efefef;
  --landing-mail: #d9d9d9;

  --footer-light-back: #f5f5f5;
  --footer-light-back-disc: #ebebeb;

  --campaign-mint-title: #8a8a8a;
  --campaign-register-back: #1d1d1d;
  --campaign-gray-text: #adadad;
  --campaign-input-back: #f1f1f1;
  --campaign-italic-text: #dcdcdc;
  --campaign-btn-icon: #494949;

  --logo-carousel-back: #000000;

  --public-back: #462a57;

  --landing-footer: #271136;

  --landing-searchbar: rgba(35, 11, 48, 0.63);
  --landing-searchbar22: rgba(35, 11, 48, 0.726);

  --upcoming-drop-bg: #220028;
  --asmp-detail-row-title: #a7a7a7;

  --loyality-sub-heading: #bababa;
  --purple: #9c5af1;

  --black-opc-back: rgba(0, 0, 0, 0.3);
  --img-black-opc-back: rgba(0, 0, 0, 0.589);

  --white-opc-back: rgba(255, 255, 255, 0.09);

  --chat-background: rgba(0, 0, 0, 0.637);
}

* {
  margin: 0;
}

body {
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* white color text */
.white-text {
  color: var(--white) !important;
}

.black-text {
  color: var(--black) !important;
  margin-bottom: 0px;
}

/* gray color text */
.gray-text {
  color: var(--loyality-sub-heading) !important;
}

.gray-secondary-text {
  color: var(--gray-secondary) !important;
}

/* red text */
.red-text {
  color: var(--theme-red) !important;
}

/* green text */
.green-text {
  color: var(--growth-green) !important;
}

.bg-green {
  background-color: var(--growth-green) !important;
  color: var(--white);
}

/* reb button bacground */
.bg-btn {
  background-color: var(--theme-red);
  border: none;
  color: var(--white);
  cursor: pointer;
  border-radius: 5px;
}

a {
  color: var(--bs-link-color);
  text-decoration: none !important;
}

.link234 {
  text-decoration: underline !important;
}

.error-border {
  border: 1px solid red;
}

#bg-btn-disabled {
  background-color: var(--disabled-button);
  border: none !important;
  color: var(--disabled-button-text) !important;
  pointer-events: none;
  border-radius: 5px;
}

#bg-btn-disabled-outline {
  background-color: var(--disabled-button);
  border: none !important;
  color: var(--disabled-button-text) !important;
  pointer-events: none;
}

.white-button {
  background-color: var(--white);
  color: var(--text-secondary);
  font-weight: 700;
  border: 1px solid var(--text-secondary);
  border-radius: 5px;
  width: 150px;
  margin-right: 8px;
}

.white-button:hover {
  color: var(--theme-red);
  border: 1px solid var(--theme-red);
}

.bg-btn-outline {
  background-color: transparent;
  border: 2px solid var(--theme-red);
  color: var(--theme-red);
  z-index: 999;
}

/* rare button */
.bg-rare {
  background: linear-gradient(
    97.45deg,
    #ffa800 4.37%,
    #e2e700 87.86%
  ) !important;
  color: var(--salesCard-back) !important;
}

/* saerchbar */
.searchbar {
  background: rgba(0, 0, 0, 0.3) !important;
}

/* searchbar border */
.searchbar-border {
  border: 2px solid var(--seachbar) !important;
}

/* footer background */
.footer-back {
  background-color: var(--footer-back) !important;
}

/* ---------------------------------------------nabar start here */

/* navbar active link */
.active-link {
  color: var(--theme-red);
  border-bottom: 2px solid var(--theme-red);
}

/* -----------------------------------------------navbar end here */

/* -----------------------------------------------wallet start here */

.makeStyles-root-1 .MuiDialogTitle-root {
  background-color: var(--theme-red) !important;
}

.MuiTypography-h6 {
  font-family: "Poppins";
  color: #d8d8d8;
  /* font-weight: 600 !important; */
}

.MuiButton-root {
  color: white !important;
  font-family: "Poppins";
}

.MuiButton-root :hover {
  /* color: var(--theme-red) !important; */
  font-family: "Poppins";
}

.makeStyles-root-1 .MuiDialogTitle-root .MuiIconButton-root {
  color: white;
  max-width: fit-content;
}

.MuiButton-root span {
  font-size: 13px;
}

.MuiButton-label {
  text-transform: capitalize;
}

/* -----------------------------------------------wallet end here */

.login-button {
  width: 60%;
  height: 50px;
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 20px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login-button .icon-img {
  height: 30px;
  margin-right: 10px;
}

/* #phantom {
  background-color: var(--white);
  color: var(--black);
} */

#phantom span {
  color: var(--white) !important;
  font-weight: 600 !important;
  letter-spacing: none !important;
  text-transform: none !important;
  font-family: var(--font) !important;
}

/* audio bar */
.audio-placement {
  /* width: 100%; */
  /* display: block; */
  position: absolute;
  bottom: 40px;
  right: 40px;
}

/* Scrollbar css */

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;

  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(170, 169, 169);
}

/* Animation */
/* body, head {
  display: block;
  font-size: 30px;
  color: transparent;
} 

 head::before, head::after,
body::before, body::after {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  content: ".";
  mix-blend-mode: screen;
  animation: 200s -27s move infinite ease-in-out alternate;
}


body::before {
  text-shadow: -0.3598786769em 0.9663546447em 10px rgba(255, 173, 0, 0.9), 0.3514801842em 1.4182911999em 10px rgba(190, 0, 255, 0.9), 2.2751084054em 0.4289735831em 10px rgba(174, 0, 255, 0.9);
  animation-duration: 200s;
  animation-delay: -27s;
}

body::after {
  text-shadow: 0.4748790623em 2.2375819213em 10px rgba(236, 0, 255, 0.9), 1.2684021033em 2.2838953715em 10px rgba(255, 21, 0, 0.9), 1.6709268127em 0.46256217em 10px rgba(239, 28, 81, 0.9);
  animation-duration: 150s;
  animation-delay: -32s;
}

head::before {
  text-shadow: 1.9451068477em 2.4838289469em 10px rgba(183, 255, 0, 0.9), 1.0217295725em -0.0449108235em 10px rgba(239, 28, 81, 0.9), 0.6145172173em 0.1778636382em 10px rgba(239, 28, 81, 0.9);
  animation-duration: 120s;
  animation-delay: -23s;
}

head::after {
  text-shadow: 1.6387337605em 0.7014873082em 10px rgba(255, 85, 0, 0.9), 1.9254052578em 0.9046137506em 10px rgba(255, 186, 0, 0.9), 0.3366937109em 1.0493804911em 10px rgba(255, 0, 129, 0.9);
  animation-duration: 80s;
  animation-delay: -19s;
}

@keyframes move {
  from {
    transform: rotate(0deg) scale(12) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(20px);
  }
} */

/* hidden chat logos */
.chatCopyright_fe39 {
  visibility: hidden !important;
}

.copy_c23e {
  visibility: hidden !important;
}

.gb-error-text {
  color: red;
}

.ml-5 {
  margin-left: 5px;
}

button {
  cursor: pointer !important;
}
