/* responsive section */
@media screen and (max-width: 1500px) {
  body .press-left-img {
    width: 400px;
    height: 500px;
  }

  body .press-img-inside-section {
    position: relative;
    top: 270px;
    /* width: 100%; */
  }

  body .press-right-img {
    width: 240px;
    height: 240px;
  }

  body .p-r-left,
  body .p-r-right,
  body .press-release-column {
    height: 500px;
  }

  body .press-inside-main {
    font-size: 24px;
  }
}

@media screen and (max-width: 1400px) {
  body .explore-track-btn-icon {
    font-size: 20px;
  }

  body .btn-solid,
  body .btn-outline {
    font-size: 12px;
  }

  body .q-icon {
    font-size: 48px;
  }
}

@media screen and (max-width: 1500px) {
  .storeFrontPage_header .header-image-area {
    width: 50vh;
    height: 50vh;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 38px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 1400px) {
  body .heighest-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .upcomingdrops-album-card-bg-img {
    position: relative;
    height: 260px;
    width: 258px;
  }

  .heighe-ranking-album-section .upcomingdrops-album-card-bg-img {
    position: relative;
    height: 220px;
    width: 218px;
  }

  .storeFrontPage_header .header-image-area {
    width: 40vh;
    height: 40vh;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 38px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }

  .heighe-ranking-album-section .upcomingdrops-album-card {
    width: 220px;
    height: 360px;
  }

  .heighe-ranking-album-section .upcomingdrop-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .upcomingdrops-album-card {
    width: 260px;
    height: 400px;
  }

  body .rank-collection {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  body .storeFrontPage_middle_collection {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  body .button {
    height: 50px;
  }

  body .bg-btn-outline {
    height: 50px;
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  body .home-card-carous .upcomingdrops-album-card {
    width: 200px;
    height: 360px;
  }

  body .home-card-carous .upcomingdrops-album-card-bg-img {
    width: 200px;
    height: 260px;
  }
}

@media (min-width: 1025px) and (max-width: 1099px) {
  .storeFrontPage_header .header_text .title {
    font-size: 6.2vw !important;
    line-height: 7vw !important;
    text-align: center;
    margin: auto;
  }

  .storeFrontPage_header .header_text .infooo {
    font-size: 2vw !important;
    line-height: 3vw !important;
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1100px) {
  body .storeFrontPage {
    width: 90%;
  }

  body .storeFrontPage_header {
    display: flex;
    flex-direction: column;
  }

  body .header_text {
    text-align: center;
    font-size: 18px;
  }

  body #header-buttons {
    justify-content: center;
    width: 90%;
    margin: auto;
  }

  body .header_image img {
    width: 450px;
    object-fit: contain;
  }

  body .storeFrontPage_middle_header {
    flex-direction: column;
  }

  body .button,
  body .bg-btn-outline {
    font-size: 14px;
    width: 150px;
  }

  body .initial-media,
  body .storeFrontPage_middle_header p {
    font-size: 20px;
  }

  body .initial-info {
    font-size: 14px !important;
  }

  body .banner .title {
    font-size: 25px;
    text-align: left !important;
  }

  body .banner .info {
    font-size: 14px;
    line-height: 24px;
  }

  body .banner button {
    font-size: 14px;
  }

  body .banner_major_image {
    width: 400px;
  }

  /* body .top-selling-full-screen {
    width: 100%;
    background-color: var(--black);
  } */

  body .top-ranking-section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 15px;
  }

  /* body #top-selling-items p {
    color: var(--white);
  } */

  body .rank-collection {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 15px;
  }

  body .storeFrontPage_middle_collection {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 15px;
  }

  body .best-performing-section,
  body .heighest-ranking-album-section,
  body .red-square-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 15px;
  }

  body .upcomingdrops-album-card {
    width: 230px;
    height: 380px;
  }

  .upcomingdrops-album-card-bg-img {
    position: relative;
    height: 186px;
    width: 220px;
  }

  .heighe-ranking-album-section .upcomingdrops-album-card {
    width: 195px;
    height: 320px;
  }

  .heighe-ranking-album-section .upcomingdrops-album-card-bg-img {
    position: relative;
    height: 180px;
    width: 194px;
  }

  .heighe-ranking-album-section .upcomingdrop-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  body .asmp-detail-row {
    width: 80%;
    margin-left: 30px;
  }

  body .press-inside-main {
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  body .flex-container {
    flex-direction: column;
  }

  body .col-3,
  body .col-9 {
    width: 100%;
    margin-bottom: 20px;
  }

  body .ayosat-title {
    font-size: 28px;
  }

  body .q-icon {
    font-size: 40px;
  }

  body .explore-track-flex {
    flex-direction: column;
  }

  body .album-track-section {
    width: 100%;
    border-radius: 30px;
    margin-top: 20px;
  }

  body .assumption-section {
    width: 100%;
  }

  body .explore-track-price-section {
    margin: auto !important;
  }

  body .explore-assumption-main-title {
    font-size: 26px;
  }

  body .press-left-img {
    width: 300px;
    height: 400px;
  }

  body .press-img-inside-section {
    position: relative;
    top: 200px;
    /* width: 100%; */
  }

  body .press-right-img {
    width: 190px;
    height: 190px;
  }

  body .p-r-left,
  body .p-r-right,
  body .press-release-column {
    height: 400px;
  }

  body .press-inside-main {
    font-size: 16px;
  }

  body .press-desc {
    margin-top: 10px;

    font-size: 12px;
    color: var(--white);
  }

  body .press-inside-genre {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  body .press-inside-genre-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  body .press-release-section {
    flex-direction: column;
  }

  body .press-section {
    height: auto;
    padding: 20px 0 20px 0;
  }

  body .press-section-content {
    width: 90%;
  }

  body .p-r-left {
    width: 80%;
  }

  body .p-r-right,
  body .press-release-column {
    width: 90%;
    height: 500px;
  }

  body .press-left-img {
    margin: auto;
    width: 500px;
    height: 500px;
  }

  body .press-right-row {
    margin-top: 30px;
  }

  body .press-img-inside-section {
    position: relative;
    top: 350px;
    /* width: 100%; */
  }
}

@media (max-width: 480px) {

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

  .question-bottom-banner {
    display: none !important;
  }

  .heighest-ranking-album-section {
    height: 420px;
  }

  /* .press-section-pad {
    padding-left: 50px !important;
  } */

  .popup-view-section3 {
    width: 80% !important;
  }

  .popup-view-section2 {
    width: 80% !important;
  }

  .home-card-carousel .swiper-slide-active {
    transform: scale(0) !important;
    transition: all 200ms linear;
    transition-timing-function: ease-in-out;
  }
}

@media (min-width: 900px) and (max-width: 1099px) {
  body .storeFrontPage_header {
    height: 66vh !important;
  }
}

/* @media (min-width: 360px) and (max-width: 480px) {
  .register-parts .register-parts-social-btn {
    width: 86% !important;
  }

  .register-parts .register-parts-social-btn-disabled {
    width: 86% !important;
  }
} */

@media (max-width: 480px) {
  .join-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  body #back-img {
    /* display: none; */
  }

  body .storeFrontPage {
    width: 90%;
    margin-bottom: 40px;
  }

  .landing-alliance {
    margin-top: 16px !important;
  }

  body .storeFrontPage_header {
    display: flex;
    flex-direction: column;
  }

  .register-input input {
    font-size: 12px !important;
  }

  body .header_text {
    text-align: center;
    font-size: 14px;
  }

  body .title {
    text-align: center;
    font-size: 18px;
    width: 42%;
    margin: auto;
    margin-bottom: 10px;
  }

  body .time-down {
    margin-right: 30px !important;
  }

  body .infooo {
    text-align: center;
    font-size: 14px;
    width: 40%;
    margin: auto;
  }

  body #header-buttons {
    justify-content: center;
  }

  body .header_image img {
    width: 300px;
    object-fit: contain;
  }

  body .storeFrontPage_middle_header {
    flex-direction: column;
  }

  body .button,
  body .bg-btn-outline {
    font-size: 14px;
  }

  body .initial-media,
  body .storeFrontPage_middle_header p {
    font-size: 20px;
  }

  body .initial-info {
    font-size: 14px !important;
  }

  body .banner {
    flex-direction: column;
    height: auto;
    text-align: left !important;
    margin: auto;
    width: 90%;
  }

  #albums-mobile-view .album-coming_soon-img {
    height: 200px;
  }

  body .banner .title {
    font-size: 25px;
    text-align: left !important;
    width: 100%;
  }

  body .banner .info {
    font-size: 14px;
    line-height: 24px;
  }

  body .banner button {
    font-size: 14px;
  }

  body .banner_major_image {
    width: 346px;
  }

  body .top-selling-full-screen {
    width: 100%;
    background-color: var(--black);
  }

  body .heighest-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
    margin-bottom: 28px !important;
  }

  body .heighe-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
    margin-bottom: -14px !important;
  }

  body .card-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .top-ranking-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    overflow-x: scroll;
    width: auto;
  }

  body .top-ranking-section::-webkit-scrollbar {
    height: 5px;
    background-color: var(--gray);
    border-radius: 10px;
  }

  body .top-ranking-section::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: var(--theme-red);
  }

  body #top-selling-items p {
    color: var(--white);
  }

  body .rank-collection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .storeFrontPage_middle_collection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .best-performing-section,
  body .red-square-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .custom-table3 {
    overflow-y: scroll;
  }

  body .flex-item-left23 {
    overflow-x: scroll;
  }

  body .header-image-area {
    width: 283px !important;
    height: 284px !important;
    margin-right: 0px;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 38px;
    margin-top: 10px;
    /* box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.5); */
  }

  body .flex-item-left23::-webkit-scrollbar {
    height: 10px;
  }

  /* Handle */
  body .flex-item-left23::-webkit-scrollbar-thumb {
    background: var(--input-border);
    border-radius: 5px;
  }

  body .flex-container {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  body .ayosat-title {
    font-size: 22px;
    margin-left: 5px !important;
  }

  body .roadmap-section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  body .landing-mail {
    height: 40px;
  }

  body .landing-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 178px;
    width: 231px;
    background-color: white;
    border-radius: 20px;
  }

  body .album-tracks-img {
    width: 100%;
    height: auto;
  }

  .landing-album-row-img {
    width: 20px !important;
    height: 20px !important;
  }

  .row-text {
    font-size: 14px !important;
  }

  .asmp-header-text {
    font-size: 14px !important;
  }

  .asmp-detail-row-title {
    font-weight: 400;
    font-size: 12px !important;
  }

  body .explore-track-price-section {
    width: 100%;
  }

  body .explore-assumption-main-title {
    font-size: 16px;
    line-height: 22px;
  }

  .upcomingdrops-album-card {
    width: 250px;
    height: 350px;
    margin-bottom: 15px;
    border-radius: 30px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: var(--upcoming-drop-bg);
    margin-bottom: 20px !important;
  }

  .upcomingdrops-album-card-bg-img {
    width: 227px !important;
    height: 230px !important;
  }

  .upcomingdrop-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 2.5rem;
    color: var(--white);
  }

  .storeFrontPage_header .home-countdown {
    margin-left: 6%;
  }

  .storeFrontPage_headerC .home-countdown {
    margin-left: 28%;
  }

  .storeFrontPage_headerC .home-countdown .timeC-block {
    margin-right: 20px !important;
  }

  /* .register-parts {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 96% !important;
  } */

  /* .register-parts .register-parts-social-btn {
    width: 90%;
  }

  .register-parts .register-parts-social-btn-disabled {
    width: 90%;
  }

  .register-parts .white-text234 .white-text {
    font-size: 10px !important;
    margin-left: 5px !important;
  }

  .register-parts .white-text {
    font-size: 13px !important;
  } */

  .MuiButton-root span {
    font-size: 8px !important;
  }

  .MuiButton-iconSizeMedium> :first-child {
    font-size: 1px;
    display: none;
  }

  .ques-title {
    font-size: 32px !important;
    margin-top: 50px !important;
    line-height: 48px !important;
    text-align: left !important;
  }

  /* .register-parts2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 96% !important;
  }

  .register-parts2 .register-input {
    height: 36px;
    border-radius: 15px !important;
  } */

  .explore-red-solid-button span {
    font-size: 12px;
  }

  .explore-red-outline-button span {
    font-size: 12px;
  }

  .explore-red-outline-button {
    width: 174px !important;
  }

  body .header-image-area .header-image-area2 {
    width: 274px;
    height: 310px;
    margin-right: 1px;
    /* background: rgba(255, 255, 255, 0.09); */
    border-radius: 38px;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.5);
  }

  body .header-image-area2 {
    width: 300px;
    height: 330px;
    margin-right: 1px;
    /* background: rgba(255, 255, 255, 0.09); */
    border-radius: 38px;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.5);
  }

  body .genrecard-text {
    width: 100%;
    height: 100%;
  }

  body .listing-card-img {
    width: 140px !important;
    height: 140px !important;
  }
}

@media (min-width: 340px) and (max-width: 490px) {
  .storeFrontPage_header .header_text .title {
    width: 40% !important;
  }

  .storeFrontPage_header .header_text .infooo {
    width: 40% !important;
  }
}

@media (min-width: 491px) and (max-width: 569px) {
  .storeFrontPage_header .header_text .title {
    width: 50% !important;
  }

  .storeFrontPage_header .header_text .infooo {
    width: 40% !important;
  }
}

@media (max-width: 380px) {
  body .upcomingdrops-album-card {
    width: 210px;
    height: 380px;
  }

  .upcomingdrops-album-card-bg-img {
    width: 209px !important;
  }

  body .header-image-area {
    width: 253px !important;
    height: 254px !important;
    margin-top: 20px;
  }
}

@media (min-width: 480px) and (max-width: 580px) {
  .storeFrontPage_header .header-image-area {
    width: 31vh;
    height: 31vh;
    margin-right: 10px;
  }

  .storeFrontPage_header .header_text .title {
    font-size: 26px !important;
    line-height: 30px !important;
    margin: auto;
    width: 42%;
    text-align: center;
  }

  .storeFrontPage_header .header_text .infooo {
    font-size: 13px !important;
    margin-bottom: 20px;
    width: 50%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .home-countdown {
    justify-content: center;
  }
}

@media (min-width: 360px) and (max-width: 500px) {
  .storeFrontPage234 .storeFrontPage_header {
    height: 44vh;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .storeFrontPage234 .storeFrontPage_header {
    height: 56vh;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .storeFrontPage_header {
    height: 66vh !important;
  }

  .storeFrontPage_header .header-image-area {
    width: 40vh;
    height: 40vh;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .upcomingdrops-album-card-bg-img {
    width: 230px !important;
    height: 230px !important;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  body #back-img {
    /* display: none; */
  }

  .heighe-ranking-album-section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    margin-top: 20px;
    width: 100%;
  }

  body .press-left-img .press-desc {
    display: none;
  }

  .storeFrontPage_header .header_text .title {
    font-size: 7.2vw !important;
    line-height: 9vw !important;
    width: 60%;
  }

  body .storeFrontPage {
    width: 90%;
    margin-bottom: 40px;
  }

  body .storeFrontPage_header {
    display: flex;
    flex-direction: column;
  }

  .register-input input {
    font-size: 12px !important;
  }

  body .storeFrontPage_headerC .header_text {
    text-align: left;
    font-size: 14px;
  }

  body .title {
    text-align: center;
    font-size: 18px;
    width: 75%;
    margin: auto;
    margin-bottom: 10px;
  }

  body .time-down {
    margin-right: 30px !important;
  }

  body .infooo {
    text-align: center;
    font-size: 2vw !important;
    width: 55%;
    margin: auto;
  }

  .large-right-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
  }

  .red-detail-square {
    width: 180px !important;
    height: 180px !important;
  }

  .red-detail-square .headText {
    font-size: 18px !important;
    line-height: 20px;
    margin-top: -90px;
  }

  body #header-buttons {
    justify-content: center;
    margin-bottom: 10px;
  }

  body .header_image img {
    width: 300px;
    object-fit: contain;
  }

  body .storeFrontPage_middle_header {
    flex-direction: column;
  }

  body .button,
  body .bg-btn-outline {
    font-size: 14px;
  }

  body .initial-media,
  body .storeFrontPage_middle_header p {
    font-size: 20px;
  }

  body .initial-info {
    font-size: 14px !important;
  }

  body .banner {
    flex-direction: column;
    height: auto;
    text-align: left !important;
    margin: auto;
    width: 90%;
  }

  body .banner .title {
    font-size: 25px;
    text-align: left !important;
    width: 100%;
  }

  body .banner .info {
    font-size: 14px;
    line-height: 24px;
  }

  body .banner button {
    font-size: 14px;
  }

  body .banner_major_image {
    width: 346px;
  }

  body .top-selling-full-screen {
    width: 100%;
    background-color: var(--black);
  }

  body .heighest-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
    margin-bottom: -14px !important;
  }

  body .card-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .top-ranking-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    overflow-x: scroll;
    width: auto;
  }

  body .top-ranking-section::-webkit-scrollbar {
    height: 5px;
    background-color: var(--gray);
    border-radius: 10px;
  }

  body .top-ranking-section::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: var(--theme-red);
  }

  body #top-selling-items p {
    color: var(--white);
  }

  body .rank-collection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .storeFrontPage_middle_collection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .best-performing-section,
  body .red-square-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .custom-table3 {
    overflow-y: scroll;
  }

  body .flex-item-left23 {
    overflow-x: scroll;
  }

  body .header-image-area {
    width: 380px !important;
    height: 380px !important;
    margin-right: 0px;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 38px;
    /* box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.5); */
  }

  body .flex-item-left23::-webkit-scrollbar {
    height: 10px;
  }

  /* Handle */
  body .flex-item-left23::-webkit-scrollbar-thumb {
    background: var(--input-border);
    border-radius: 5px;
  }

  body .flex-container {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  body .ayosat-title {
    font-size: 30px;
  }

  body .roadmap-section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  body .landing-mail {
    height: 40px;
  }

  body .landing-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 180px;
    background-color: linear-gradient(180deg,
        hsla(0, 0%, 200%, 0.1956),
        hsla(0, 0%, 200%, 0.205)) 100%;
    border-radius: 20px;
  }

  body .album-tracks-img {
    width: 100%;
    height: auto;
  }

  .landing-album-row-img {
    width: 20px !important;
    height: 20px !important;
  }

  .row-text {
    font-size: 14px !important;
  }

  .asmp-header-text {
    font-size: 14px !important;
  }

  .asmp-detail-row-title {
    font-weight: 400;
    font-size: 12px !important;
  }

  body .explore-track-price-section {
    width: 100%;
  }

  body .explore-assumption-main-title {
    font-size: 16px;
    line-height: 22px;
  }

  .upcomingdrops-album-card {
    width: 250px;
    height: 350px;
    margin-bottom: 15px;
    border-radius: 30px;
    margin-right: 10px;
    background-color: var(--upcoming-drop-bg);
    margin-bottom: 20px !important;
  }

  .upcomingdrop-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 2.5rem;
    color: var(--white);
  }

  .storeFrontPage_header .home-countdown {
    align-items: center;
    justify-content: center;
  }

  .storeFrontPage_headerC .home-countdown {
    margin-left: 28%;
  }

  .storeFrontPage_headerC .home-countdown .timeC-block {
    margin-right: 20px !important;
  }

  .MuiButton-root span {
    font-size: 8px !important;
  }

  .MuiButton-iconSizeMedium> :first-child {
    font-size: 1px;
    display: none;
  }

  .ques-title {
    font-size: 32px !important;
    margin-top: 50px !important;
    line-height: 48px !important;
    text-align: center !important;
  }

  .explore-red-solid-button span {
    font-size: 12px;
  }

  body .header-image-area .header-image-area2 {
    width: 274px;
    height: 310px;
    margin-right: 1px;
    /* background: rgba(255, 255, 255, 0.09); */
    border-radius: 38px;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

  .bottom-desc .TCTable {
    width: 100%;
  }

  .question-bottom-banner {
    display: none !important;
  }

  .popup-view-section3 {
    width: 80% !important;
  }

  .popup-view-section2 {
    width: 80% !important;
  }
}

@media (min-width: 768px) and (max-width: 899px) {
  body .landing-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    width: 217px;
    background-color: white;
    border-radius: 20px;
  }
}

@media (min-width: 620px) and (max-width: 767px) {
  .upcomingdrops-album-card-bg-img {
    width: 230px !important;
    height: 230px !important;
  }
}

@media (min-width: 900px) and (max-width: 1024px) {
  body .landing-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 180px;
    background-color: white;
    border-radius: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body .salesCard {
    height: 180px;
  }

  .ayosat-title {
    margin: 0px !important;
  }

  .landing-alliance .ayosat-title {
    margin-left: 16px !important;
  }

  .ayosat-sub-title {
    margin-top: 20px;
  }

  body .title-loyalties {
    font-size: 15px;
    text-align: left;
    margin-bottom: 20px;
    color: var(--white);
    font-weight: 700;
  }

  body #back-img {
    /* display: none; */
  }

  body .storeFrontPage {
    width: 80%;
  }

  body .page-content {
    width: 80%;
    display: grid;
    margin: auto;
  }

  body .storeFrontPage_header {
    display: flex;
    flex-direction: column;
  }

  body .header_text {
    text-align: center;
    font-size: 14px;
    width: 600px;
  }

  body .title {
    text-align: center;
    font-size: 32px;
    width: 90%;
    line-height: 46px !important;
    margin: auto;
    margin-bottom: 30px;
  }

  body .infooo {
    text-align: center;
    font-size: 14px;
    width: 50%;
    margin-bottom: 30px !important;
    margin: auto;
  }

  body #header-buttons {
    justify-content: center;
  }

  body .header_image img {
    width: 300px;
    object-fit: contain;
  }

  body .storeFrontPage_middle_header {
    flex-direction: column;
  }

  body .button,
  body .bg-btn-outline {
    font-size: 14px;
  }

  body .initial-media,
  body .storeFrontPage_middle_header p {
    font-size: 20px;
  }

  body .initial-info {
    font-size: 14px !important;
  }

  body .banner {
    flex-direction: column;
    height: auto;
    text-align: left !important;
    margin: auto;
    width: 90%;
  }

  body .banner .title {
    font-size: 25px;
    text-align: left !important;
    width: 100%;
  }

  body .banner .info {
    font-size: 14px;
    line-height: 24px;
  }

  body .banner button {
    font-size: 14px;
  }

  body .banner_major_image {
    width: 346px;
  }

  body .top-selling-full-screen {
    width: 100%;
    background-color: var(--black);
  }

  body .heighest-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
    margin-bottom: -50px !important;
  }

  body .heighe-ranking-album-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    margin-bottom: 0px !important;
  }

  body .card-ranking-album-section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .top-ranking-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    overflow-x: scroll;
    width: auto;
  }

  body .top-ranking-section::-webkit-scrollbar {
    height: 5px;
    background-color: var(--gray);
    border-radius: 10px;
  }

  body .top-ranking-section::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: var(--theme-red);
  }

  body #top-selling-items p {
    color: var(--white);
  }

  body .rank-collection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .storeFrontPage_middle_collection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .best-performing-section,
  body .red-square-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
  }

  body .custom-table3 {
    overflow-y: scroll;
  }

  body .flex-item-left23 {
    overflow-x: scroll;
  }

  body .header-image-area {
    width: 450px;
    height: 442px;
    margin-right: 0px;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 38px;
    /* box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.5); */
  }

  body .flex-item-left23::-webkit-scrollbar {
    height: 10px;
  }

  /* Handle */
  body .flex-item-left23::-webkit-scrollbar-thumb {
    background: var(--input-border);
    border-radius: 5px;
  }

  body .flex-container {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  body .ayosat-title {
    font-size: 30px;
  }

  body .roadmap-section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  body .landing-mail {
    height: 40px;
  }

  body .album-tracks-img {
    width: 100%;
    height: auto;
  }

  body .explore-track-price-section {
    width: 100%;
  }

  body .explore-assumption-main-title {
    font-size: 20px;
  }

  .upcomingdrops-album-card {
    width: 230px;
    height: 400px;
    margin-bottom: 15px;
    border-radius: 30px;
    margin-right: 10px;
    background-color: var(--upcoming-drop-bg);
    margin-bottom: 20px !important;
  }

  .upcomingdrops-album-card-bg-img {
    width: 230px !important;
    height: 230px !important;
  }

  .upcomingdrop-title {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 2.5rem;
    color: var(--white);
  }

  .home-countdown {
    align-items: center;
    justify-content: center;
  }

  .explore-red-solid-button span {
    font-size: 12px;
  }

  body .header-image-area .header-image-area2 {
    width: 274px;
    height: 310px;
    margin-right: 1px;
    /* background: rgba(255, 255, 255, 0.09); */
    border-radius: 38px;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .heighe-ranking-album-section .upcomingdrops-album-card {
    width: 230px;
    height: 380px;
    margin-bottom: 50px;
    border-radius: 30px;
    margin-right: 10px;
    background-color: var(--upcoming-drop-bg);
    margin-bottom: 20px !important;
  }

  .heighe-ranking-album-section .upcomingdrops-album-card-bg-img {
    width: 230px;
    height: 244px;
  }

  .heighe-ranking-album-section .upcomingdrop-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    height: 2.5rem;
    color: var(--white);
  }
}

@media screen and (max-width: 600px) {
  body .press-left-img {
    margin: auto;
    width: 400px;
    height: 400px;
  }

  body .press-img-inside-section {
    position: relative;
    top: 200px;
    /* width: 100%; */
  }

  body .press-right-row {
    flex-direction: column;
  }

  body .press-right-img {
    width: 100px;
    height: 100px;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  body .roadmap-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  body .explore-red-outline-button,
  body .explore-red-solid-button {
    width: 150px;
    height: 40px;

    font-size: 10px;
  }

  body .press-left-img {
    margin: auto;
    width: 300px;
    height: 300px;
  }

  body .press-img-inside-section {
    position: relative;
    top: 125px;
    /* width: 100%; */
  }
}

@media (min-width: 1500px) and (max-width: 2200px) {
  .descrip {
    margin-top: 2px;
    font-size: 15px;
  }
}

.bottom-desc h1 {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: var(--white);
}

.pay-with {
  display: flex;
  justify-content: center;
}

.buy-paypal-wrapper {
  padding: 30px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.1056) 0%,
      rgba(255, 255, 255, 0.055)) 100%;
  border-radius: 20px;
  margin-top: 40px !important;
}

.buy-paypal-wrapper .form-input-label {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
}

.buy-paypal-wrapper .form-input {
  width: 100%;
  border-radius: 64px;
  border: 1px solid #d8d8d8;
  padding: 10px 15px;
}

.paypal-icon {
  font-size: 24px;
  margin-right: 10px;
}

.buy-paypal {
  justify-content: center;
  display: flex;
  align-items: center;
}

.paypal-title-area {
  display: flex;
  justify-content: right;
}

.button-buy-paypal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  font-weight: 600 !important;
  border-radius: 50px;
  background-color: #253b80;
}

.button-buy-paypal:hover {
  border: 1px solid #3c5ec5cc;
}

.ppay {
  font-size: 20px;
}

.descrip {
  margin-top: -8px;
  font-size: 15px;
}

#back-img {
  display: block;
}

.desktop-2 {
  background-color: transparent;
  margin: auto;
  overflow-x: hidden;
  /* overflow-x: hidden; */
}

.page {
  background-color: transparent;
  margin-top: 80px;
}

.home {
  background-color: transparent;
  margin-top: 220px !important;
}

.storeFrontPage {
  background-color: transparent;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.finantial .sub-descr {
  margin-left: 10px !important;
  margin-bottom: 6px;
  color: gray !important;
}

.finantial-box {
  /* border: 1px solid white; */
  margin-top: 20px;
  width: max-content !important;
}

.album-inf .descrip {
  color: rgb(185, 185, 185) !important;
}

.card-ranking-album-section2 {
  display: flex;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 20px;
  width: 100%;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
}

@media (max-width: 480px) {
  .card-ranking-album-section2 {
    display: block !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .card-ranking-album-section2 {
    display: block !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card-ranking-album-section2 {
    display: block !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }
}

  .miniting-loading-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 1111;

    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
  }

  .miniting-loading-screen-text {
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    align-items: center;
    text-align: center;
    color: var(--white);
  }

  .miniting-loading-screen-items {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .miniting-loading-screen-items .close-btn {
    position: absolute;
    top: 26px;
    right: -5px;
    cursor: pointer;
  }

  .miniting-loading-screen-items .close-btn span {
    color: var(--theme-red);
    font-size: 30px;
    cursor: pointer;
  }

  .ayosat-title-head {
    font-weight: 600;
    font-size: 22px;
    /* line-height: 115%; */
    color: var(--theme-red);
    margin: 20px 0 0 0;
    font-family: poppins;
  }

  .miniting-loaded-congratulation {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */
    margin-bottom: 0;
    text-align: center;
    color: var(--white);
  }

  .miniting-loaded-screen-text {
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    align-items: center;
    text-align: center;
    color: goldenrod;
    margin-bottom: 12px;
  }

  .finantial-box .descrip {
    color: red !important;
    padding: 10px;
    margin-bottom: 0px !important;
  }

  .finantial .descrip {
    margin-bottom: 8px;
  }

  .storeFrontPage_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header_text {
    width: 700px;
    font-weight: 400;
    font-size: 18px;
    color: var(--gray);
  }

  .titlecart {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 20px;
    color: var(--black);
  }

  .infooo {
    margin-top: 40px;
  }

  .days-text {
    margin-bottom: 0px;

    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: var(--loyality-sub-heading);
  }

  .storeFrontPage .heading {
    font-size: 30px;
    font-weight: 800;
  }

  .days-count-text {
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 35px;
    line-height: 52px;
    color: var(--white);
  }

  .header-image-area {
    width: 430px;
    height: 420px;
    margin-right: 40px;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 38px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }

  .header-image-area2 {
    width: 493px;
    height: 464px;
    margin-right: 40px;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 38px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }

  .buttons {
    display: flex;
    margin-top: 20px;
    z-index: 300;
  }

  button {
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    z-index: 300;
  }

  .bg-btn-outline {
    background-color: transparent;
    border: 2px solid var(--theme-red);
    color: var(--theme-red);
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;

    border-radius: 5px;
  }

  .bg-btn-solid {
    background-color: transparent;
    border: 2px solid var(--theme-red);
    color: var(--theme-red);
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;

    border-radius: 5px;
  }

  .button {
    background: var(--theme-red);
    color: #ffffff;
    border: 0;
    cursor: pointer;
    z-index: 300;
  }

  /* swiper */
  .home-card-carousel {
    margin-top: 10px;
    margin-bottom: 30px;
    height: 500px;
    z-index: 40;
    overflow: visible;
  }

  .swiper {
    overflow: hidden !important;
  }

  .home-card-carousel .swiper-slide {
    transform: scale(0.8) !important;
  }

  .home-card-carousel .swiper-slide-active {
    transform: scale(1) !important;
    transition: all 200ms linear;
    transition-timing-function: ease-in-out;
  }

  .swiper-css-mode>.swiper-wrapper {
    overflow: visible !important;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0px !important;
    right: auto;
    z-index: 999;
    height: 84px !important;
    width: 40px !important;
    background-color: var(--theme-red) !important;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0px !important;
    left: auto;
    z-index: 999;
    height: 84px !important;
    width: 40px !important;
    background-color: var(--theme-red) !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 40% !important;
    padding: 10px;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
  }

  .create_your_own {
    background: transparent;
    color: var(--theme-red);
    border: 1px solid var(--theme-red);
  }

  .header_image img {
    width: 500px;
    object-fit: contain;
  }

  .storeFrontPage_middle {
    margin-top: 30px;
    text-align: center;
  }

  .storeFrontPage_middle_header {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    align-items: flex-start;
  }

  .storeFrontPage_middle_header_top {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    align-items: flex-start;
  }

  .storeFrontPage_middle_header_top p {
    font-weight: 700;
    font-size: 25px;
  }

  .storeFrontPage_middle_header p {
    font-weight: 700;
    font-size: 25px;
    padding: 15px 15px 15px 0px;
  }

  .initial-media {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .initial-info {
    font-weight: 400 !important;
    font-size: 15px !important;
    color: var(--gray) !important;
    margin-top: -5px !important;
  }

  .banner {
    display: flex;
    height: 360px;
    border-radius: 20px;
    background: var(--theme-red);
    width: 80%;
    margin: auto;
  }

  .banner_image {
    width: 45%;
    display: flex;
    align-items: center;
  }

  .banner_info {
    width: 55%;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .banner_major_image {
    width: 560px;
  }

  .banner .title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #fff;
    margin: 20px 0 20px 0px;
  }

  .banner .info {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #ffffff;
  }

  .banner button {
    width: 230px;
    height: 50px;
    background-color: var(--white);
    border: 0;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: var(--theme-red);
    z-index: 300;
  }

  .best-performing-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .storeFrontPage_middle_collection {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .heighest-ranking-album-section {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .heighe-ranking-album-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .card-ranking-album-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .join-ranking-album-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    justify-content: center;
  }

  .top-ranking-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .rank-collection {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-top: 20px;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  .dash-page {
    background-color: transparent;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }

  .back {
    padding-top: 30px;
    padding-left: 30px;
    cursor: pointer;
  }

  .title {
    text-align: left;
    font-size: 26px;
    font-weight: 900;
    margin-top: 30px;
  }

  .description {
    max-width: 60vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10px;
  }

  /* .storeFrontPage {
  flex: 1;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
} */

  .header {
    display: flex;
    justify-content: space-between;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    margin-bottom: 20px;
    margin-right: 60px !important;
    margin-left: 60px !important;
  }

  .flex-item-left {
    background-color: #fff;
    padding: 10px;
    flex: 30%;
    height: auto;
    border: 1px solid #c8cfcd;
    border-radius: 10px;
  }

  .flex-item-left23 {
    background-color: #fff;
    padding: 10px;
    flex: 100%;
    height: auto;
    border: 1px solid #c8cfcd;
    border-radius: 10px;
  }

  .left-header {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    color: gray;
    align-items: center;
    margin-bottom: 10px;
  }

  .text-right {
    display: flex;
    align-items: center;
  }

  .text-left {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px !important;
  }

  .main-image {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .explore-track-btn-icon {
    font-size: 25px;
  }

  .symbol {
    margin-right: 8px;
    font-size: 20px;
  }

  .symbol-text {
    margin-left: 1px;
    font-size: 20px;
  }

  .gray-text {
    font-size: 15px;
    font-weight: 500;
    color: gray;
  }

  .pint-text {
    color: #e83363;
    text-decoration: underline;
  }

  .owned-info {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-weight: 500;
  }

  .owned-image {
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .price-area {
    font-weight: 300;
  }

  .price {
    font-size: 48px;
    font-weight: 800;
  }

  .currency {
    font-weight: 400;
  }

  .bracket {
    font-weight: 500;
    font-size: 20px;
    color: #c8cfcd;
  }

  .btn-solid {
    background-color: #e83363;
    width: 100%;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .btn-outline {
    background-color: #fff;
    width: 100%;
    border: 2px solid #e83363;
    border-radius: 5px;
    color: #e83363;
    font-weight: 700;
    font-size: 16px;
    padding: 10px;
  }

  .flex-item-right {
    margin-left: 10px;
    padding: 10px;
    flex: 80%;
  }

  .h2 {
    font-weight: 700;
  }

  .explore-profile-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 20px;
    background-size: 100%;
  }

  .explore-profile-name {
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 12px;
  }

  .first-text {
    font-size: 15px;
    margin: 0;
  }

  .second-text {
    font-size: 15px;
    margin: 0;
    text-decoration: underline;
    color: #e83363;
  }

  .description {
    font-size: 15px;
    color: gray;
    text-align: left;
  }

  .album-tracks-img {
    width: 500px;
    height: 500px;
    z-index: 10;

    overflow: hidden;
    border-radius: 20px;
    filter: drop-shadow(38.6786px 35.3239px 86.5179px rgba(0, 0, 0, 0.45));
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25);
  }

  .explore-track-flex {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .album-track-section {
    position: relative;

    width: 60%;
    height: 400px;

    z-index: 4;
    padding: 10px 0 10px 0;
    border-radius: 0 30px 30px 0;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.061) 0%,
        rgba(0, 0, 0, 0.244) 100%);
    backdrop-filter: blur(35px);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25);

    overflow-y: scroll;
  }

  .album-track-section::-webkit-scrollbar-thumb {
    background-color: var(--theme-red);
  }

  .explore-track-price-section {
    width: 500px;
  }

  .middle-form {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: space-around;
    border: 1px solid #c8cfcd;
  }

  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }

  .dropdown-toggle {
    border: 1px solid #c8cfcd;
    display: block;
  }

  .dropdown-toggle {
    border: 1px solid #c8cfcd;
    display: block;
  }

  .bottom-hd {
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    color: var(--white);
  }

  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }

  .avatar {
    width: 80px;
    border-radius: 15%;
  }

  .row-name {
    font-size: 16px;
    align-content: center;
  }

  .track-dec {
    border: 0.5px solid #c8cfcd;
    padding: 0 6px 0 6px;
    border-radius: 5px;
    font-size: 13px;
    color: #c8cfcd;
  }

  .btn-pink {
    background-color: #e83363;
    color: #fff;
    font-weight: 500;
  }

  .detail-table {
    margin-top: 10px;
  }

  .detail-row {
    display: flex;
  }

  .avatar {
    width: 80px;
    border-radius: 15%;
    flex-grow: 1;
  }

  .row-name {
    font-size: 16px;
    width: 100px;
    align-content: center;
    flex-grow: 1;
    margin-left: 30px;
  }

  .track-dec {
    border: 0.5px solid #c8cfcd;
    padding: 0 6px 0 6px;
    border-radius: 5px;
    font-size: 13px;
    color: #c8cfcd;
  }

  .progress-section {
    align-self: center;
    flex-grow: 3;
  }

  .progress {
    height: 8px;
  }

  .progress-bar {
    width: 60%;

    background-color: #e83363;
  }

  .upper-text {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: gray;
  }

  .btn-pink {
    margin-left: 10px;
    padding: 10px;
    background-color: #e83363;
    color: #fff;
    font-weight: 500;
    flex-grow: 1;
    align-self: center;
  }

  .middle {
    margin-top: 30px;
  }

  .middle_header {
    display: flex;
    justify-content: space-between;
  }

  button {
    height: 45px;
    width: 170px;
    border-radius: 5px;
    background: #e0335d;
    color: #ffffff;
    border: 0;
    z-index: 300;
  }

  .middle_collection {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 75vw;
  }

  .cart-page>.cartPage {
    background-color: transparent;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }

  .cart_title {
    font-weight: 800;
    font-size: 26px;
    color: white;
  }

  .cart_infoo {
    font-weight: 400;
    font-size: 16px;
    color: var(--gray);
    margin-top: -10px;
  }

  .custom-table2 {
    font-family: "Poppins" !important;
  }

  .custom-table2 thead th {
    background: #f3f3f4;
    font-size: 14px;
  }

  .custom-table2 td {
    padding: 1rem 2rem;
    border-top: 1px solid #ebe9f1;
    vertical-align: middle;
    font-size: 14px;
  }

  .custom-table3 {
    /* border: 1px solid #f3f3f4; */
    margin-left: 10px;
    margin-right: 10px;
    width: 98% !important;
    font-family: "Poppins" !important;
    margin-bottom: 30px !important;
  }

  .custom-table3 thead th,
  .custom-table4 thead th {
    /* background: #f3f3f4; */
    font-size: 16px;
    height: 50px !important;
    border-bottom: 2px solid #ebe9f1;
  }

  .custom-table3 td,
  .custom-table4 td {
    font-size: 15px;
    border-bottom: 2px solid #ebe9f1 !important;
    vertical-align: middle;
    height: 50px !important;
    /* border: none; */
  }

  .custom-table3 td img {
    width: 38px;
    height: 38px;
    background-size: 100%;
    background-position: center;
    border-radius: 50%;
    margin-right: 10px;
  }

  .row-user-name {
    font-size: 15px;
    font-weight: 400;
    color: var(--salesCard-back);
    margin-bottom: 0px !important;
  }

  .row-user-id {
    font-size: 10px;
    margin-bottom: 0px !important;
  }

  .custom-table button {
    width: auto;
    height: auto;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 4px;
  }

  .custom-table button.enable {
    background-color: #28c76f;
  }

  .custom-table button.disable {
    background-color: rgb(191, 94, 94);
  }

  .custom-table span.status-text {
    padding: 0.3rem 0.5rem;
    text-align: center;
    border-radius: 10rem;
    font-size: 12px;
  }

  .custom-table span.status-type {
    color: #e0335d;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(224, 51, 93, 0.32);
  }

  .custom-table span.status-active {
    color: #28c76f;
    background-color: rgba(40, 199, 111, 0.12);
  }

  .custom-table span.status-inactive {
    color: #82868b;
    background-color: rgba(130, 134, 139, 0.12);
  }

  .custom-table .select-user-role {
    border: 1px solid #ebe9f1;
    border-radius: 6px;
    padding: 0.4rem 1rem;
    font-size: 12px;
    width: 100%;
  }

  .custom-table .chevron-icon {
    color: #82868b;
  }

  .custom-table .select-wrapper {
    position: relative;
  }

  .custom-table .select-wrapper .select-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .custom-table4 {
    /* border: 1px solid #f3f3f4; */
    margin-left: 10px;
    margin-right: 10px;
    width: 92% !important;
    font-family: "Poppins" !important;
  }

  .custom-table5 {
    /* border: 1px solid #f3f3f4; */
    margin-left: 10px;
    /* margin-right: 10px;  */
    width: 98% !important;
    font-family: "Poppins" !important;
  }

  .custom-table5 thead th {
    /* background: #f3f3f4; */
    font-size: 16px;
    height: 50px !important;
    border-bottom: 2px solid #ebe9f1;
  }

  .custom-table5 td {
    font-size: 15px;
    border-bottom: 2px solid #ebe9f1 !important;
    vertical-align: middle;
    height: 50px !important;
    /* border: none; */
  }

  .custom-table5 tbody {
    height: 150px !important;
    overflow-y: auto !important;
  }

  /* .custom-table4 thead th {
  background: #f3f3f4;
  font-size: 16px;
  height: 50px !important;
} */

  /* .custom-table4 td {
  font-size: 15px;
  border-top: 1px solid #ebe9f1;
  border: none;
  vertical-align: middle;
  height: 50px !important;
} */

  .play-ic {
    color: var(--theme-red);
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  .stop-ic {
    color: var(--theme-red);
    height: 30px;
    width: 30px;
  }

  .ayosat-title {
    font-weight: 900;
    font-size: 28px;
    line-height: 115%;
    color: var(--white);
    margin: 40px 0 60px 0;
    font-family: poppins;
  }

  .landing-sponsor22 {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.061) 0%,
        rgba(0, 0, 0, 0.244) 100%);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    width: 100%;

    height: auto;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    margin-top: 60px;
    /* box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25); */
  }

  .ayosat-sub-title {
    font-weight: 400;
    font-size: 16px;
    color: #bababa;
    font-style: normal;
  }

  .landing-sponsor,
  .landing-sponsor-no-border {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.061) 0%,
        rgba(0, 0, 0, 0.244) 100%);
    backdrop-filter: blur(35px);
    /* Note: backdrop-filter has minimal browser support */
    width: 80%;
    border-radius: 30px;
    height: auto;
    margin: auto;
    overflow: hidden;
    margin-top: 120px;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.05);
  }

  .landing-sponsor-no-border {
    background: none !important;
    backdrop-filter: none !important;
    /* Note: backdrop-filter has minimal browser support */

    box-shadow: none !important;
  }

  .landing-roadmap {
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.061) 0%, rgba(0, 0, 0, 0.244) 100%); */
    /* backdrop-filter: blur(35px); */
    /* Note: backdrop-filter has minimal browser support */
    width: 80%;
    border-radius: 30px;
    height: auto;
    margin: auto;
    overflow: hidden;
    margin-top: 160px;
    /* box-shadow: 1px 0px 2px 2px rgba(255, 255, 255, 0.25); */
  }

  .landing-alliance {
    width: 80%;
    margin-bottom: 20px;
    overflow: hidden;
    margin: auto;
    margin-top: 50px;
  }

  .roadmap-img {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
  }

  .red-square-section {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-top: 20px;
    width: 80%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: auto;
    justify-items: center;
  }

  .roadmap-section {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-top: 20px;
    width: 80%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: auto;
    justify-items: center;
    margin-bottom: 100px;
  }

  .red-square {
    width: 160px;
    height: 160px;
    background: #ef1c51;
    border-radius: 20px;
  }

  .landing-btn-icon {
    font-size: 25px;
    margin-right: 5px;
  }

  .landing-mail-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-left: 20px; */
  }

  .landing-mail {
    width: 80%;
    background-color: var(--landing-input);
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    z-index: 20;
  }

  .landing-mail-icon {
    background-color: var(--landing-mail);
    color: var(--theme-red);
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .landing-input {
    border: none;
    width: 100%;
    background-color: var(--landing-input);
    height: 45px;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
  }

  .storeFrontPage234 {
    background-color: transparent;
    margin: auto;
    margin-bottom: 4px;
    background-color: transparent;
    width: 74% !important;
    padding-top: 30px;
    overflow: hidden;
  }

  .q-icon {
    font-size: 50px;
  }

  .landing-album-row {
    /* display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); */
    padding-left: 30px;
    display: flex;

    padding-top: 8px;
    padding-bottom: 8px;

    align-items: center;
    cursor: pointer;
  }

  .landing-album-row:hover {
    background: rgba(0, 0, 0, 0.432);
  }

  .landing-album-row-img {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    margin-right: 30px;

    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25);
  }

  .row-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    margin-bottom: 0px;
    margin-left: 10px;
    min-width: 20px;
  }

  .loyalties-mit-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loyalties-mit-button-wrapper p {
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
    color: var(--asmp-detail-row-title) !important;
  }

  .loyalties-mit-button-wrapper-warning p {
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
    color: var(--warning) !important;
  }

  .Mui-disabled {
    background-color: var(--gray) !important;
    color: var(--black) !important;
  }

  .explore-red-outline-button,
  .explore-red-solid-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 230px;
    height: 40px;

    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 10px;
    /* text-decoration: none; */
  }

  .explore-red-solid-button {
    background-color: var(--theme-red);
  }

  .explore-red-solid-button:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  .explore-red-outline-button {
    background: rgba(255, 255, 255, 0.09);
    border: 2px solid var(--theme-red);
  }

  .explore-btn-icon {
    font-size: 30px;
    margin-right: 5px;
  }

  .explore-assumption-main-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: var(--white);
  }

  .assumption-section {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.061) 0%,
        rgba(0, 0, 0, 0.244) 100%);
    backdrop-filter: blur(35px);
    /* Note: backdrop-filter has minimal browser support */
    width: 70%;
    border-radius: 10px;
    height: auto;
    margin-top: 20px;
    padding: 20px 0 10px 0;
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25);
    margin-bottom: 200px;
  }

  .asmp-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
  }

  .asmp-header:hover {
    background: rgba(0, 0, 0, 0.432);
    overflow: hidden;
  }

  .asmp-header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .asmp-header-icon {
    font-size: 35px;
    color: var(--theme-red);
  }

  .asmp-hr {
    border: 1px solid #a7a7a7;
  }

  .asmp-detail-row {
    width: 70%;
    margin-left: 30px;
  }

  .asmp-detail-row-title {
    color: var(--asmp-detail-row-title);
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
  }

  .asmp-detail-row-amount {
    color: var(--theme-red);
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
  }

  /* press release section */
  .press-section {
    margin-top: 40px;
    width: 100%;
    background: var(--black-opc-back);
    backdrop-filter: blur(10px);
    height: auto;
    padding: 10px 0 10px 0;
    margin-bottom: 80px;
  }

  .press-section-content {
    width: 80%;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .press-release-section {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .p-r-left,
  .p-r-right {
    width: 50%;
    height: 550px;
    margin-top: 30px;
  }

  .p-r-left {
    flex: 2;
    /* margin-right: 10px; */
  }

  .p-r-right {
    flex: 3;

    /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
    /* margin-left: 10px; */
  }

  .press-release-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 550px;
  }

  .press-releasse-slider-section {
    width: 100%;
    margin-left: 20px;
    z-index: 40;
  }

  @media screen and (max-width: 480px) {
    body .press-inside-main {
      font-size: 16px;
      margin-top: 0px !important;
      line-height: 20px;
    }

    .bottom-desc .descrip span {
      font-size: 12px;
    }

    .bottom-desc .white-text {
      font-size: 12px !important;
    }

    .storeFrontPage .descrip {
      font-size: 12px !important;
    }

    .bottom-desc .TCTable .tracksTB th {
      font-size: 12px;
    }

    .desktop-62-container .heading-sub {
      font-size: 12px;
      line-height: 20px;
    }

    .bottom-desc .TCTable .tracksTB td {
      font-size: 10px;
    }

    body .press-left-img .press-desc {
      display: none !important;
    }

    .flexSection .explore-red-solid-button {
      width: 200px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    body .press-left-img .press-desc {
      display: none !important;
    }
  }

  .press-left-img {
    width: 456px;
    height: 550px;
    border-radius: 20px;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #390037 100%);
    box-shadow: 0px 20px 20px 3px rgba(0, 0, 0, 0.5);
  }

  .logo-wrap {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.1056) 0%,
        rgba(255, 255, 255, 0.055)) 100%;
    backdrop-filter: blur(17px);
  }

  .press-img-inside-section {
    position: relative;
    top: 260px;
    /* width: 100%; */
  }

  .press-inside-title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    /* border-left: 3px solid var(--theme-red); */
  }

  @media (max-width: 480px) {

    body .landing-sponsor,
    .landing-sponsor-no-border {
      margin-top: 60px !important;
    }

    body .landing-sponsor {
      margin-bottom: 20px !important;
    }
  }

  .press-inside-main {
    flex: 3;
    padding-left: 20px;
    margin-bottom: 10px;
    line-height: 25px;
    font-weight: 700;
    font-size: 20px;
    color: var(--white);
  }

  .press-inside-genre {
    flex: 3;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: var(--theme-red);
    border-radius: 5px;
  }

  .press-inside-genre-text {
    margin-bottom: 0;
    padding-left: 20px;

    font-weight: 700;
    font-size: 15px;
    color: var(--white);
  }

  .left-border-red {
    height: 30px;
    width: 5px;

    border-radius: 0px 4px 4px 0px;
    background-color: var(--theme-red);
  }

  .press-time-icon {
    font-size: 18px;
    color: var(--theme-red);
  }

  .press-time {
    margin-bottom: 0px;
    font-size: 12px;
    margin-left: 10px;

    color: var(--white);
  }

  .press-desc {
    margin-top: 10px;

    font-size: 14px;
    color: var(--white);
  }

  .press-right-row {
    display: flex;
    align-items: center;
  }

  .press-right-img {
    width: 265px;
    height: 265px;
    border-radius: 20px;
    flex: 2;

    overflow: hidden;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.842);
    box-shadow: 0px 20px 20px 3px rgba(0, 0, 0, 0.5);
  }

  .press-right-row-details {
    flex: 3;
  }

  .tracksTB td {
    padding: 10px;
    border-top: 1px solid #ebe9f1;
    vertical-align: middle;
  }

  .TCTable {
    width: 80%;
    margin-top: 40px;
    margin-bottom: 80px;
  }

  .tracksTB th {
    color: white;
    font-size: 16px;
  }

  .tracksTB th,
  td {
    color: white;
    font-size: 14px;
  }

  .card-image {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    z-index: 30;
  }

  .buttons {
    display: flex;
    margin-top: 20px;
  }

  .buttons-card {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

  button {
    height: 54px;
    width: 220px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
  }

  .button-center {
    height: 50px;
    width: 202px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
  }

  .button:hover {
    background: linear-gradient(180deg,
        rgba(255, 0, 0, 0.32),
        rgba(97, 0, 255, 0.32) 99.99%,
        hsla(0, 0%, 85.1%, 0)) !important;
    color: #ffffff;
    border: 0;
    cursor: pointer;
  }

  @media (min-width: 1600px) and (max-width: 1900px) {
    .heighe-ranking-album-section {
      grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
  }

  @media (min-width: 1600px) {
    .heighe-ranking-album-section {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      margin-top: 20px;
    }

    .heighest-ranking-album-section .swiper-slide {
      margin-right: 25px !important;
    }
  }

  @media screen and (max-width: 700px) {
    body .button {
      height: 50px !important;
    }
  }

  .cart-added {
    background-color: var(--theme-red);
  }

  .top-slider-button-wrapper {
    display: flex;
    gap: 12px;
  }

  .top-slider-button-wrapper .button {
    border-radius: 4px;
  }

  @media screen and (max-width: 490px) {
    .top-slider-button-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
    }
  }

  .timer-warning {
    margin-top: 10px;
  }

  .timer-warning p {
    color: red;
  }