.royal-container {
  /* width: 90%; */
}

.img {
  width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  background-size: "cover";
  background-position: center;
}

.bottomSection {
  position: relative;
  width: 100%;
  /* background-color: var(--salesCard-back); */
  background-color: var(--black);
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

.priceSectionBlack {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-bottom: 15;
  flex-wrap: wrap;
}

.priceWhite {
  font-size: 20px;
  color: white;
  font-weight: 700;
}

.whiteText {
  color: var(--white);
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
}

.icon {
  color: var(--white);
  font-size: 18px;
  margin-right: 4px;
}

.smallText {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 0;
}

.bottomBar {
  width: 90%;
  margin: auto;
}

.horizontalLine {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 6px;
  background-color: var(--white);
  border-radius: 50px;
}

.filled {
  height: 100%;
  width: 60%;
  background-color: var(--theme-red);
  border-radius: 50px;
}

.range {
  display: flex;
  justify-content: space-between;
}

.icon-section {
  display: flex;
  align-items: center;
}

.best-performing-bg-img {
  width: 500px;
  height: 500px;

  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25);
}

.congrats-card {
  margin: auto;
  width: 337px;
  height: auto;
  padding-top: 15px;
  margin-bottom: 30px;
  background: #220028;
  border-radius: 30px;
  padding-bottom: 20px;
}

.congrats-card-img {
  width: 284px;
  height: 290px;
  margin: auto;
  border-radius: 30px;
}

.congrats-card-title {
  font-size: 20px;
  margin-top: 10px;
  line-height: 31px;
  text-align: center;
  color: var(--white);
}

.congrats-hash {
  font-weight: 700;
  font-size: 20.8073px;
  line-height: 31px;
  text-align: center;

  color: var(--theme-red);
}

.rare-tag {
  width: 115px;
  height: 40px;
  margin: auto;

  background: linear-gradient(0deg, #ffb800 0%, #d69a00 52.6%, #ffd66d 100%);
  box-shadow: inset 0px -4px 6px rgba(0, 0, 0, 0.25),
    inset 0px 4px 6px rgba(255, 255, 255, 0.25);
  border-radius: 11px;
}

.congrats-card-sol {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin-top: 10px;
  text-align: center;
  color: var(--white);
}
