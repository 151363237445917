/* responsive section */

@media screen and (max-width: 1000px) {
  body .red-dot {
    width: 50px;
    height: 50px;
    background-color: var(--theme-red);
    border-radius: 50%;
  }

  body .roadmap-title {
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    color: var(--black);
    margin-bottom: 0px;
  }

  body .roadmap-desc {
    font-size: 14px;
    line-height: 27px;
    text-transform: capitalize;
  }

  body .best-performing-rmt,
  body .heighest-ranking-album-rmt {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
  }
}

@media screen and (max-width: 700px) {
  body .best-performing-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  body .red-text-back {
    font-size: 12px;
    line-height: 18px;
    width: auto;
  }

  body .best-performing-gray-text {
    font-size: 14px;
    line-height: 21px;
  }

  body #responsive-card-price {
    display: block;
  }

  body #desktop-card-price {
  }

  body .top-ranking-album-title {
    color: var(--white);
    font-size: 18px;
  }

  body .top-ranking-dollar {
    color: var(--white);
    font-size: 16px;
  }

  /*ranked-collection- card  */

  body .ranked-collection-img {
    height: 180px;
  }

  body .heart-circle {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: var(--white);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }

  body .ranked-collection-title {
    font-size: 15px;
    line-height: 18px;
  }

  body .ranked-collection-number {
    font-size: 25px;
    line-height: 38px;
  }

  body .ranked-collection-type {
    font-size: 12px;
    line-height: 18px;
  }

  body .ranked-collection-rmt {
    font-size: 14px;
    line-height: 21px;
  }

  body .ranked-collection-dollar {
    font-size: 14px;
    line-height: 21px;
  }

  body .button {
    height: auto;
  }

  /* crator card */
  body .user_name {
    font-size: 16px;
    font-weight: 800;
  }

  body .home-page-carousel {
    margin-top: 4rem !important;
    flex-direction: column-reverse;
  }

  body .home-carousel-img {
    position: relative;
    width: 100% !important;
    height: auto;
    border-radius: 10px;
  }

  body .home-carousel-heading {
    font-size: 22px !important;
  }

  body .home-carousel-desc {
    font-size: 14px !important;
  }

  body .home-carousel-price {
    background-color: var(--white);
    width: 80% !important;
  }

  body .home-carousel-icon {
    font-size: 20px;
  }

  body .flex-buttons {
    justify-content: center;
  }

  body .top-ranking-container {
    width: 300px;
  }
}

#responsive-card-price {
  display: none;
}

#desktop-card-price {
  display: block;
}

/* ------------------creator card */
.creator-card {
  height: fit-content;
  width: 100%;
  height: 240px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: var(--white);
}

.creator_banner_image {
  height: 108px;
  width: 100%;
  background-color: #ebebeb;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.creator-avatar {
  margin-top: -70px;
  width: 100%;
}

.avatar-image {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto !important;
  border: 5px solid var(--white);
}

.user_name {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}

/*------------------------------- best-performing-card */
.best-performing-card,
.dark-best-performing-card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  background-color: var(--white);
  /* min-height: 500px; */
}

.dark-best-performing-card {
  background-color: var(--dark-bg) !important;
}

/* #add-min-height {
  min-height: 535px;
} */

.best-performing-bg-img {
  position: relative;
  height: 250px;
  width: 100%;
}

.best-performing-content {
  padding: 8px;
}

.best-performing-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  height: 2.5rem;
}

.best-performing-bottom-section {
  display: flex;
  justify-content: space-between;
  margin-top: -5px;
}

.best-performing-bold {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.best-performing-rmt {
  font-weight: 700;
  font-size: 23px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: var(--theme-red);
}

.best-performing-gray-text {
  color: var(--gray);
  font-size: 14px;
  font-weight: 400;
  /* padding: 10px; */
}

.row-v-header {
  font-weight: 1000;
  color: var(--theme-red);
  font-size: 32px;
  margin-top: -18px;
  margin-bottom: -2px;
}

.row-v-header2 {
  font-weight: 1000;
  font-size: 32px;
  margin-top: -18px;
  margin-bottom: -2px;
}

.row-v-sub {
  color: var(--gray);
  font-size: 14px;
}

.best-performing-gray-text2 {
  color: var(--gray);
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
}

/*------------------------------- HeighestRankingAlbum  */

.heighest-ranking-album-card {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--upcoming-drop-bg);
  /* min-height: 480px; */
}

.upcomingdrops-album-card {
  width: 280px;
  height: auto;
  padding-bottom: 25px;

  padding-top: 1px;
  margin-bottom: 15px;
  border-radius: 30px;
  background-color: var(--upcoming-drop-bg);
  box-shadow: 0px 10px 10px -4px rgba(0, 0, 0, 0.5);
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.upcomingdrops-album-card:hover {
  border: 1px solid var(--theme-red);
}

@media screen and (max-width: 700px) {
  .heighest-ranking-album-card-bg-img {
    position: relative;
    height: 200px !important;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .upcomingdrops-album-card-bg-img {
    position: relative;
    height: 200px;
    width: 90%;
    border-radius: 30px;
    margin-bottom: 10px;
  }

  .heighest-ranking-album-title,
  .upcomingdrop-title {
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 2.5rem;
  }
}

.heighest-ranking-album-card-bg-img {
  position: relative;
  height: 274px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.upcomingdrops-album-card-bg-img {
  position: relative;
  height: 285px;
  width: 278px;
  border-radius: 30px;
  margin-bottom: 10px;
}

.heighest-ranking-album-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

@media screen and (max-width: 1100px) {
  .home-countdown {
    align-items: center;
    justify-content: center;
  }
}

.BOXx2 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: #151515 !important;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 10px !important;
  overflow: auto;
  border-radius: 15px;
}

.BOXx2 .close-btn {
  display: flex;
  justify-content: right;
}

.BOXx2 .close-btn .cls-btnn {
  width: 30px !important;
  height: 30px !important;
  border-radius: 8px !important;
}

.upcomingdrop-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 2.5rem;
  color: var(--white);
}

.heighe-ranking-album-section .upcomingdrop-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 2.5rem;
  color: var(--white);
}

.heighest-ranking-album-text {
  padding: 8px;
}

.easy-ranking-album-card .heighest-ranking-album-text {
  padding: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.heighest-ranking-album-rev {
  padding: 2px;
}

.heighest-ranking-album-rmt {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--theme-red);
  margin-right: 10px;
}

/*------------------------------- HeighestRankingAlbum end  */

/*------------------------------- HeighestRankingAlbum  */

.tracks-album-card {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 480px;
}

.tracks-album-card-bg-img {
  position: relative;
  height: 220px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.tracks-album-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.tracks-album-text {
  padding: 8px;
}

.tracks-album-rev {
  padding: 2px;
}

.tracks-album-rmt {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--theme-red);
  margin-right: 10px;
}

/*------------------------------- HeighestRankingAlbum end  */

/*--------------- top ranking */
.top-ranking-container {
  width: 100%;
  height: 101px;
  margin-bottom: 20px;
  display: flex;
}

.top-ranking-img_box {
  width: 83px;
  height: 95px;
  border-radius: 8px;
  margin-right: 5px;
  margin-left: 1px;
  margin-top: 2px;
  margin-bottom: 2px;
  object-fit: cover;
}

.top-ranking-content {
  width: 60%;
  margin-left: 10px;
  margin-top: 8px;
}

.top-ranking-album-title {
  color: var(--black);
  font-weight: 600;
  font-size: 16px;
}

.top-ranking-price {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.top-ranking-percent {
  color: var(--growth-green);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.top-ranking-dollar {
  color: var(--black);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

/*--------------- top ranking end*/
/* ---------------- ranking collection start */

.heart-circle {
  display: none;
}

.ranked-collection-container {
  border-radius: 10px;
  width: 100%;
  border: 1px solid var(--table-border);
  margin-bottom: 10px;
  background-color: var(--white);
}

.ranked-collection-img-section {
  padding: 3px;
}

.ranked-collection-img {
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  /* box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25); */
}

.ranked-collection-content {
  align-items: center;
}

.ranked-collection-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-top: 10px;
  text-align: center;
  color: var(--text-secondary);
  height: 2rem;
}

.ranked-collection-number {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  color: var(--text-secondary);
}

/* rare button */
.ranked-collection-type {
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50px;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 8px;
  padding: 6px;
}

.ranked-collection-rmt {
  text-align: center;
  width: 50%;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;
  padding: 8px;
  margin-bottom: 8px;
}

.ranked-collection-dollar {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--gray);
  margin-bottom: 10px;
}

/* ---------------- ranking collection end*/

/*  */

.red-text-back {
  background-color: var(--theme-red);
  color: var(--white);
  border-radius: 50px;
  width: 200px;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
}

/* home grid card*/

.red-text-live {
  background-color: var(--theme-red);
  color: var(--white);
  border-radius: 50px;
  width: 100px;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
}

/* home page carousel */
.home-page-carousel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.home-carousel-left {
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 60px;
}

.home-carousel-heading {
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  color: var(--heading-text);
}

.home-carousel-icon-section {
  display: flex;
  margin-top: 4px;
  font-size: 24px;
}

.home-carousel-icon {
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 25px;
}

.home-carousel-icon:hover {
  color: var(--theme-red);
}

.home-carousel-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.home-carousel-price {
  background-color: var(--white);
  width: 400px;
  border: 1px solid var(--table-border);
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.home-carousel-right {
  flex: 1;
}

.home-carousel-img {
  position: relative;
  width: 30vw;
  height: 50vh;
  border-radius: 10px;
}

.cart_content {
  display: flex;
}

@media (max-width: 480px) {
  .carts {
    width: 100%;
    padding-right: 0px !important;
  }

  .cart_content {
    display: flex;
    width: 110% !important;
  }

  .nft_image span {
    color: white;
    font-size: 14px;
  }

  .name_contents .pop-red {
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: rgb(173, 173, 173);
    margin-bottom: 0.1rem;
    margin-top: 10px;
  }
  
  .nft_image {
    width: 26% !important;
  }

  .nft_img {
    width: 47px;
    margin-right: 0px;
  }

  .checkout_info {
    width: 110% !important;
    background: #151515;
    border-radius: 6px;
    padding: 15px 18px;
  }

  .cart-countdown{
    width: 110% !important;
  }
}


.carts {
  width: 100%;
  padding-right: 30px;
}

.cart_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #d3d3d3;
  margin-bottom: 10px;
}

.nft_img {
  width: 70px;
  margin-right: 20px;
}

.nft_image {
  width: 60%;
}

.nft_image span {
  color: white;
}

img {
  border-radius: 4px;
  /* margin-right: 10px; */
}

span {
  font-size: 16px;
}

.nft_name {
  width: 23%;
  display: flex;
}

.span_circle {
  height: 38px;
  width: 38px;
  background-color: #ef5da8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  margin-top: 14px;
}

h4 {
  color: #151515;
  margin-bottom: 4px;
}

p {
  color: #858fa2;
}

.type {
  width: 18%;
}

.span2 {
  background-color: #0bd06b;
  color: white;
  border-radius: 39px;
  padding: 10px 24px;
  font-size: 12px;
  font-weight: 700;
}

.price {
  width: 23%;
  display: flex;
  justify-content: center;
}

.price-qty {
  width: 20%;
  display: flex;
  justify-content: center;
  color: white;
}

.BOXx2 .price-qty {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
}

.minus {
  width: 5%;
}

h4 {
  font-size: 14px;
  font-weight: 700;
  color: #ef1c51;
  margin-bottom: 3px;
}

h5 {
  font-size: 14px;
  color: #858fa2;
}

.info_text {
  margin-top: 30px;
}

p {
  color: #4a5568;
  font-size: 14px;
}

.pop-red {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgb(173, 173, 173);
  margin-bottom: 0.1rem;
  /* margin-top: 10px; */
}

.pop-red2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--theme-red);
  margin-bottom: 0.1rem;
  margin-top: 10px;
}

.count-btn {
  width: 30px;
  height: 30px;
  border-radius: 10px;
}

.BOXx2 .count-btn {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.count-qty {
  width: 30px;
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.BOXx2 .count-qty {
  width: 50px;
  display: flex;
  justify-content: center;
  margin-top: 3px;
  align-items: center;
}

.pop-red-icon {
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  color: var(--theme-red);
  margin-bottom: 0.1rem;
  margin-top: 10px;
  cursor: pointer;
}

.cart-disabled {
  cursor: help;
  visibility: 0.8;
  color: rgba(255, 255, 255, 0.582);
}
.checkout {
  color: white;
}

.checkoutNoItem {
  color: white;
  margin-bottom: 100px;
}

.usd {
  text-align: right;
  color: white;
}

.T-usd {
  text-align: right;
  color: white;
  font-size: 18px;
}

.checkout_info {
  width: 100%;
  background: #151515;
  border-radius: 6px;
  padding: 15px 18px;
}

.title {
  color: white;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

.checkout_items {
  padding-top: 15px;
  color: white;
  border-bottom: 1px solid #d8d8d8;
}

.items_inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.total_wrapper {
  margin-top: 30px;
  color: white;
  display: flex;
  justify-content: space-between;
}

.btn_wrapper {
  width: 100%;
  margin-top: 35px;
  text-align: center;
}

.button2 {
  border: none;
  outline: none;
  width: 100%;
  background: #ef1c51;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 700;
}

.track-li {
  margin-top: 30px;
  font-size: 22px;
}

/*--------------------------- home page carousel end*/

.red-dot {
  width: 70px;
  height: 70px;
  background-color: var(--theme-red);
  border-radius: 50%;
}

.roadmap-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.roadmap-title {
  margin-top: 20px;
  font-weight: 600;
  font-size: 25px;
  color: var(--black);
  margin-bottom: 0px;
}

.roadmap-desc {
  font-size: 14px;
  line-height: 27px;
  text-transform: capitalize;
}

.upcomingdrop-price {
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  color: var(--white);
  margin-bottom: 8px;
  font-weight: bold;
}

.original-price {
  margin-right: 10px;
  margin-left: 5px;
  position: relative;
  color: var(--theme-red);
  font-size: 14px;
  font-weight: 700;
}

.original-price::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 2px solid var(--theme-red);
  transform: rotate(-10deg);
}

.upcomingdrop-price-img {
  margin: 0;
  width: 35px;
  height: 35px;
}

.upcomingdrop-button {
  margin-top: 25px;
  text-align: center;
}

.upcomingdrop-button button {
  height: auto;
  width: auto;
  font-size: 13px;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--theme-red);
  color: var(--theme-red);
  font-weight: 700;
}

.upcomingdrop-button button.cart-added {
  background-color: var(--theme-red);
  color: white;
}

.upcomingdrop-button button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  background-color: var(--theme-red);
  color: white;
}

.mb-0 {
  margin-bottom: 0px !important;
}
