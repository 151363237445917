.chat-button {
    position: fixed;

    bottom: 30px;
    right: 30px;
    height: 50px;
    width: 50px;

    font-size: 25px;
    border-radius: 50px;
    box-shadow: var(--theme-red) 0 5px 20px -5px;


}

.chat-button-active {
    position: fixed;

    bottom: 30px;
    right: 30px;
    height: 50px;
    width: 50px;

    font-size: 25px;
    border-radius: 0px 50px 50px 50px;
    box-shadow: var(--theme-red) 0 5px 20px -5px;

}

.chat-title {
    position: absolute;

    color: white;
    font-size: 40px;
}


.chatCopyright_acd6, .__chat_d0b5 {
    display: none;
}

/* .main_f2a3 {
    background: var(--chat-background) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);


}

.text_e966 {
    color: var(--white) !important;
}

.input_f013 {
    background: var(--chat-background) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
} */

.hide-chat {
    display: none;
}

.show-chat {
    display: block;
    margin-bottom: 50px !important;
}

.logoIcon_bef0 {
    display: none !important;
}

/* 
.closeIcon_b57c {
    position: absolute !important;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 999;
    border: none;
} */

.chatCopyright_abd7 {
    display: none;
}