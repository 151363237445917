.audio-player {
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.9164040616246498) 64%);
    /* position: absolute;
    bottom: 0; */
    height: 30%;
    overflow: hidden;
    z-index: 99;
    z-index: 10;
}

.audio-player-body {
    width: 100%;
    position: absolute;
    /* left: 50%; */
    /* transform: translate(-50%); */
    /* bottom: 10%; */

    z-index: 999;
}

.audio-play-ic {
    color: var(--theme-red);
    height: 30px;
    width: 30px;
    padding-left: 2px;
    background-color: aliceblue;
    border-radius: 50%;
}

.audio-stop-ic {
    color: var(--theme-red);
    height: 30px;
    width: 30px;
    background-color: aliceblue;
    border-radius: 50%;
    padding: 3px;
}






.audio-player-top-view {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;

}

.audio-player-info {
    display: flex;
    align-items: center;
}

.play-pause {
    margin: 0 !important;
    position: relative;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    font-size: 15px;
    transition: 0.4s ease-in-out;
    color: var(--theme-red);
    background-color: var(--white);
    z-index: 99;


}

.play-pause:hover {
    background-color: var(--bar-bg);
}


.play-audio {
    position: relative;
    left: 2px;
}

.pause-audio {
    color: violet;
}

.mute-audio {
    color: var(--gray);
}

.mute-btn {
    font-size: 18px;
}

.audio-time-current, .audio-time-duration {
    color: white;
    font-family: 'Courier New', Courier, monospace;
    margin-left: 4px;
    text-align: center;

}

.audio-progressbar {
    /* slider varibles */
    --seek-before-width: 0;
    --seek-before-color: var(--theme-red);
    --knobby: var(--white);
    --selected-knobby: var(--white);
    --bar-height: 6px;
    --knobby-size: 12px;

    appearance: none;
    background: var(--bar-bg);
    position: relative;
    width: 100%;
    border-radius: 10px;
    height: var(--bar-height);
    outline: none;
    cursor: pointer;
}

/* safari */
.audio-progressbar::-webkit-slider-runnable-track {

    background: var(--bar-bg);
    position: relative;
    width: 100%;
    height: var(--bar-height);
    border-radius: 10px;

    outline: none;
}

/* firefox */
.audio-progressbar::-moz-range-track {
    background: var(--bar-bg);
    position: relative;
    width: 100%;
    height: var(--bar-height);
    border-radius: 10px;

    outline: none;
}

.audio-player::-moz-focus-outer {
    border: 0;
}


/* progress bar chrome */
.audio-progressbar::before {
    content: '';
    height: var(--bar-height);
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
}

/* progress bar firefox*/
.audio-progressbar::-moz-range-progress {
    width: var(--seek-before-width);
    height: var(--bar-height);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* knooby */

.audio-progressbar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: var(--knobby-size);
    width: var(--knobby-size);
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin-top: -3px;
    z-index: 20;
    box-sizing: border-box;
}

.audio-progressbar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selected-knobby);
}

/* firefox */
.audio-progressbar::-moz-range-thumb {
    height: var(--knobby-size);
    width: var(--knobby-size);
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 20;
    box-sizing: border-box;
}

.audio-progressbar::-moz-range-thumb:active {
    transform: scale(1.2);
    background: var(--selected-knobby);
}